import React from 'react';
import { Banner } from '../banner';
import { CategoriesHeader, LoyaltyBanner } from '@fingermarkglobal/cringer.components';

const Header = ({
  title = null,
  className = '',
  isHeaderTitleEnabled = true,
  showCategoriesHeader = false,
  total = null,
  isHeaderTotalEnabled = false,
  formattedTaxes = null,
  t,
  showSubtotal = true,
  showLoyaltyBanner = false,
  computedAction = () => {},
} = {}) => {
  const justifyContent = isHeaderTotalEnabled ? 'justify-between' : 'justify-center';
  const showHeaderWrapperContainer = title;
  const isHome = window.location.pathname === '/home';

  return (
    <div data-test="header" className={`flex flex-col bg-header ${className}`}>
      {showCategoriesHeader && <CategoriesHeader isIconMinimal isItemRounded={false} />}
      {showLoyaltyBanner && <LoyaltyBanner computedAction={computedAction} />}
      {!showCategoriesHeader && !isHome && <Banner />}

      {showHeaderWrapperContainer && (
        <div
          className={`flex text-6xl uppercase px-8 py-8 font-header items-center ${justifyContent}`}
        >
          {title && isHeaderTitleEnabled && (
            <>
              <div className="flex items-center justify-center">{title}</div>
              {isHeaderTotalEnabled && showSubtotal && (
                <div className="flex flex-col items-end">
                  <div>{total}</div>
                  {formattedTaxes && (
                    <div className="mt-4 text-4xl font-primary">
                      {t('headerTotalTaxes')}: {formattedTaxes}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export { Header };
