import React from 'react';

import LogoImage from '../../../assets/images/logo.svg';

const Banner = () => {
  return (
    <div className="flex flex-col items-center justify-center h-60">
      <LogoImage data-test="logo" className="h-8" />
    </div>
  );
};

export { Banner };
