import React from 'react';

import { PrimaryHome } from '../../components/stateless/primary-home';
import { SecondaryHome } from '../../components/stateless/secondary-home';
import {
  Alert,
  Loading,
  useRestaurantHomeView,
  AdminButton,
  IFrameModal,
} from '@fingermarkglobal/cringer.components';

const CustomHomeView = () => {
  const {
    t,
    state,
    onAdminActivate,
    showSecondaryHome,
    accessibilityEnabled,
    languages,
    primaryAction,
    secondaryAction,
    onChangeLanguage,
    showSecondaryAction,
    card,
    cash,
    icons,
    isOnline,
    healthMsg,
    termsAndConditionsURL,
    showTermsAndConditionsModal,
    onCloseTermsAndConditionsModal,
    setShowTermsAndConditionsModal,
    showTermsAndConditionsButton,
  } = useRestaurantHomeView();

  const params = {
    accessibilityEnabled,
    languages,
    onChangeLanguage,
    primaryAction,
    secondaryAction: showSecondaryAction && secondaryAction,
    t,
    card,
    cash,
    icons,
    healthMsg,
    termsAndConditionsURL,
    showTermsAndConditionsModal,
    onCloseTermsAndConditionsModal,
    setShowTermsAndConditionsModal,
    showTermsAndConditionsButton,
  };

  return (
    <div className="flex flex-col items-center justify-center h-full relative">
      <AdminButton onActivate={onAdminActivate} />
      {state?.matches('failure') && <Alert type="danger" message={state.context.message} />}
      {state?.matches('loading') && <Loading isFirstLoad={true} />}
      {state?.matches('success') && (
        <>
          <IFrameModal
            url={termsAndConditionsURL}
            onClose={onCloseTermsAndConditionsModal}
            showModal={showTermsAndConditionsModal}
            t={t}
            className="home-page-iframe"
          />
          {!isOnline ? (
            <Alert type="warning" message={t('homeConnectivityIssues')} />
          ) : showSecondaryHome ? (
            <SecondaryHome {...params} />
          ) : (
            <PrimaryHome {...params} />
          )}
        </>
      )}
    </div>
  );
};

export { CustomHomeView };
